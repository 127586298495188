


























import Vue from 'vue';
import Component from 'vue-class-component';

const IconCheck = () => import('common-modules/src/assets/fa-icons/regular/check.svg');
const IconClock = () => import('common-modules/src/assets/fa-icons/regular/clock.svg');
const IconExclamationTriangle = () => import('common-modules/src/assets/fa-icons/regular/exclamation-triangle.svg');
const IconSpinnerThird = () => import('common-modules/src/assets/fa-icons/duotone/spinner-third.svg');
const IconTimes = () => import('common-modules/src/assets/fa-icons/regular/times.svg');

const props = Vue.extend({
  props: {
    jwlRequest: Object,
  },
});

@Component({
  components: {
    IconCheck,
    IconClock,
    IconExclamationTriangle,
    IconSpinnerThird,
    IconTimes,
  },
})
export default class JwlUndoPublish extends props {
  changeRequest = this.jwlRequest;
  submitting = 0;
  error = null;

  performAction (targetAction: string): void {
    if (this.submitting !== 1) {
      this.submitting = 1;

      const FD = new FormData();
      FD.append('performingAction', targetAction);
      FD.append('targetElement', this.changeRequest.requestId);

      this.$store.dispatch('postData', {
        url: 'undo-publish/perform-change',
        formData: FD,
      }).then((data) => {
        this.changeRequest.request = data;
        this.submitting = 2;
      }).catch((e) => {
        this.error = e;
        this.submitting = -1;
      });
    }
  }

  requestIcon (requestState: string): string {
    switch (requestState) {
      case 'accepted':
        return 'icon-check';
      case 'declined':
        return 'icon-times';
      default:
        return 'icon-clock';
    }
  }

  formattedDate (dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleString();
  }
}
