



































import Vue from 'vue';
import Component from 'vue-class-component';
import jwlUndoPublish from '@/components/jwlUndoPublish.vue';
import { UndoPublishRequest } from '@/store/interfaces/UndoPublishRequest';
import JwlSubmitRow from '@/components/JwlSubmitRow.vue';

const CommonError = () => import('common-modules/src/components/CommonError.vue');
const CommonIcon = () => import('common-modules/src/components/CommonIcon.vue');
const JwlSearch = () => import('@/components/SisSearch.vue');

@Component({
  components: {
    CommonError,
    JwlSubmitRow,
    jwlUndoPublish,
    CommonIcon,
    JwlSearch,
  },
})
export default class JwlUndoPublish extends Vue {
  searchTerm = '';
  requests: UndoPublishRequest[] = [];
  handledRequests: UndoPublishRequest[] = [];
  loading = true;
  error = null;
  loadingHandled = 0;

  getHandled (): void {
    if (this.loadingHandled !== 1) {
      this.loadingHandled = 1;
      this.$store.dispatch('getData', 'undo-publish/requests/handled')
        .then((data) => {
          this.handledRequests = data;
          this.loadingHandled = 2;
        })
        .catch((e) => {
          this.error = e;
          this.loadingHandled = -1;
        });
    }
  }

  mounted (): void {
    this.$store.dispatch('getData', 'undo-publish/requests')
      .then((data) => {
        this.requests = data;
        this.loading = false;
      })
      .catch((e) => {
        this.error = e;
      });
  }
}
